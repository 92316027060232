<template>
  <div class="login">
    <div class="top">
      <img
        src="~assets/images/common/logo.png"
        alt
      />
      <div>
        <p>刷脸支付商户端后台</p>
        <span>智慧商业 轻松支付</span>
      </div>
    </div>
    <van-form
      @submit="onSubmit"
      class="form"
    >
      <div class="cell flex-x-start flex-xy-center">
        <img
          src="~assets/images/common/phone.png"
          alt
        />
        <van-field
          v-model="formData.account"
          name="用户名"
          label
          type="tel"
          placeholder="请输入账号"
          :rules="[{ validator: validatorPhone, message: '请输入账号' }]"
        />
      </div>
      <div class="cell flex-x-start flex-xy-center">
        <img
          src="~assets/images/common/pwd.png"
          alt
        />
        <van-field
          v-model="formData.password"
          type="password"
          name="密码"
          label
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请输入密码' }]"
        />
      </div>
      <div class="flex-x-start flex-xy-center forgetpwd">
        <van-checkbox
          v-model="checked"
          checked-color="#FD6E05"
          shape="square"
          icon-size="15"
        >记住密码</van-checkbox>
      </div>
      <van-button
        round
        block
        native-type="submit"
        class="submit-btn"
      >登录</van-button>
    </van-form>
    <div
      class="link-bottom iphonex"
      v-show="hidshow"
    >
      如果忘记密码及时和
      <a href="javascript:;">服务商管理人员</a>联系，重置密码
    </div>
  </div>
</template>

<script>
import { login, wechatAuthInfo } from 'api/common'
import { isPhone } from 'utils'
import { setCookie, getCookie } from 'utils/cookie'
export default {
  name: 'Login',
  data() {
    return {
      formData: {
        account: '',
        password: ''
      },
      checked: false,
      docmHeight: '0', //默认屏幕高度
      showHeight: '0', //实时屏幕高度
      hidshow: true //显示或者隐藏footer,
    }
  },
  mounted() {
    const remember = localStorage.getItem('remember') == 'true'
    console.log('remember', remember, typeof(remember));
    if (remember) {
      const userName = getCookie('userName')
      const userPwd = getCookie('userPwd')
      this.formData.account = userName
      this.formData.password = userPwd
      this.checked = true
    }

    //默认屏幕高度
    this.docmHeight = document.documentElement.clientHeight
    window.onresize = () => {
      return (() => {
        // 实时屏幕高度
        this.showHeight = document.body.clientHeight
      })()
    }

    const { account, password, bind } = this.$route.query
    if (account) {
      this.formData.account = account
    }
    if (password) {
      this.formData.password = password
    }
    console.log('login', localStorage.getItem('remember'))
    if (account && password) {
      this.checked = true
      this.onSubmit(false, bind)
    }
  },
  watch: {
    showHeight() {
      if (this.docmHeight > this.showHeight) {
        this.hidshow = false
      } else {
        this.hidshow = true
      }
    }
  },
  methods: {
    // 手机号码校验
    validatorPhone(val) {
      return isPhone(val)
    },
    // 点击提交
    onSubmit(needToast = false, bind = false) {
      const that = this
      login(this.formData).then((res) => {
        if (res.code === 200) {
          setCookie(this.formData.account, this.formData.password, 7)

          localStorage.setItem('remember', this.checked)
          localStorage.setItem('payToken', res.data.token)
          localStorage.setItem('payInfo', JSON.stringify(res.data.merchant))
          localStorage.setItem('userType', res.data.userType)
          localStorage.setItem('bankName', JSON.stringify(res.data.bankName))
          localStorage.setItem('businessScopeName', JSON.stringify(res.data.businessScopeName))

          this.getWechatAuthInfo()

          this.callNativeMethod(res.data.token, res.data.merchant.id)
          console.log('login success', res.data);
          if (needToast) {
            this.$toast({
              type: 'success',
              message: '登录成功',
              onClose() {
                that.$router.replace(!bind ? '/index' : '/mine')
              }
            })
          } else {
            that.$router.replace(!bind ? '/index' : '/mine')
          }
        } else {
          this.$toast({
            type: 'fail',
            message: res.msg,
            icon: 'close'
          })
        }
      })
    },
    callNativeMethod(token, merchantId) {
      const data = { 
        token: token, 
        merchantId: merchantId
      }
      if (window.webkit) {
        if (window.webkit.messageHandlers.loginSuccess) window.webkit.messageHandlers.loginSuccess.postMessage(data)
      } else if (window.android) {
        if (window.android.loginSuccess) window.android.loginSuccess(data)
      }
    },
    // 查询是否微信授权
    getWechatAuthInfo() {
      wechatAuthInfo().then((res) => {
        if (res.code === 200) {
          localStorage.setItem('wechatAuthInfo', JSON.stringify(res.data))
        }
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  background: #fff;
  height: 100vh;
  font-size: 14px;
  box-sizing: border-box;

  /deep/.van-icon-success {
    border-radius: 2px;
  }

  /deep/.van-field__error-message {
    display: none;
  }

  .top {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 33px;

    img {
      height: 97px;
      width: 97px;
    }

    div {
      p {
        font-size: 22px;
        color: #333333;
        margin-bottom: 8px;
      }

      span {
        color: rgba(51, 51, 51, 0.7);
      }
    }
  }

  .form {
    padding: 0 30px;

    img {
      height: 24px;
      width: 24px;
    }

    .cell {
      padding-bottom: 7px;
      border-bottom: 1px solid #f0f0f0;
      margin-top: 22px;

      .van-cell {
        padding: 0 13px;

        /deep/.van-field__label {
          width: auto;
        }
      }
    }

    .forgetpwd {
      margin: 12px 0 28px 5px;

      /deep/.van-checkbox__label {
        color: rgba(51, 51, 51, 0.65);
      }
    }
  }

  .link-bottom {
    position: fixed;
    bottom: 20px;
    text-align: center;
    left: 0;
    right: 0;
    color: rgba(51, 51, 51, 0.6);
    font-size: 12px;

    a {
      color: #fea869;
    }
  }
}
</style>
