import {
    get,
    post,
    patch,
    baseUrl
} from './http'

// 登录
export const login = (params) => post('/api/merchant/login', params)

// 主页数据开关
export const getIndexDataSwitch = (params) => get('/api/merchant/common/indexDataSwitch', params)

// 修改密码
export const modifyPwd = (params) => patch('/api/merchant/modifyPwd', params)

// 修改账号
export const modifyAccount = (params) => post('/api/merchant/modifyAccount', params)

// 提现交易列表
export const withdrawList = (params) => get('/api/withdrawLog/list', params)

// 首页统计数据
export const transactionRecordIndex = (params) => get('/api/transactionRecord/index', params)

// 可申请提现设备
export const withdrawable = (params) => get('/api/transactionRecord/list/withdrawable/device', params)

// 可申请提现设备
export const cannotWithdraw = (params) => get('/api/transactionRecord/list/cannotWithdraw/device', params)

// 已获得奖励设备
export const withdrawabled = (params) => get('/api/transactionRecord/list/withdrawabled/device', params)

// 交易统计列表
export const transactions = (params) => get('/api/transactionRecord/list/transactions', params)

// 申请提现
export const apply = (params) => post('/api/withdrawLog/apply', params)

// 查询设备Sn
export const deviceSn = (params) => get('/api/device/list/deviceSn', params)

// 获取授权信息
export const wechatAuthInfo = (params) => get('/api/merchant/wechatAuthInfo', params)

// 拉起微信授权
export const wxBind = (redirectUrl) => {
    window.location.href = `${baseUrl}api/merchant/common/wxAuth?institutionId=1&url=${redirectUrl}`
}

// 拉起支付宝授权
export const alipayBind = (redirectUrl) => {
    window.location.href = `${baseUrl}api/merchant/common/aliAuth?institutionId=1&url=${redirectUrl}`
}

// 微信授权绑定
export const wxAuth = (params) => post('api/merchant/wechatAuth', params)

// 支付宝授权绑定
export const alipayAuth = (params) => post('api/merchant/aliAuth', params)

// 获取轮播图
export const getSwiper = (params) => get('/api/merchant/common/carouselImage', params)

// 交易统计设备sn
export const transactionDeviceSN = (params) => get('/api/transactionRecord/list/transactionDeviceNo', params)

// 交易统计接口
export const transactionStatistics = (params) => post('/api/transactionRecord/statistics', params)

// 申请退款
export const transactionRefund = (id) => get(`/api/transactionRecord/refund/${id}`)

// 结算费率
export const settleFee = () => get("/api/merchant/settleFee")

//商户信息
export const merchantInfo = () => get("/api/merchant/merchantInfo")

//用户信息
export const userInfo = () => get("/api/merchant/userInfo")

//开户人员信息
export const openPersonnel = () => get("/api/merchant/openPersonnel")

//商户收款二维码
export const paymentCode = () => get("/api/merchant/paymentCode")

//商户收款二维码图片
export const paymentCodeImageUrl = () => get("/api/merchant/paymentCodeImageUrl")

//银盛余额查询
export const getYsBalance = () => get("/api/thirdWithdrawLog/ysfBalanceQuery")

// 银盛余额提现
export const ysWithdrawApply = (amount) => post("/api/thirdWithdrawLog/ysfWithdrawApply", { amount })

// 获取支付宝授权信息
export const alipayAuthInfo = () => get("/api/merchant/aliAuthInfo")

// 获取微信授权绑定二维码
export const getWechatAuthQrCode = () => get("/api/merchant/wechatAuthQrCode")

// 获取支付宝授权绑定二维码
export const getAlipayAuthQrCode = () => get("/api/merchant/aliAuthQrCode")

// 获取结算打款开关配置
export const getSettlePayConfig = () => get("/api/merchant/common/settlePayConfig")

// 获取平台设备奖励配置
export const getDeviceRewardConfig = () => get("/api/merchant/common/platformDeviceRewardConfig")

// 绑定音箱SN
export const postBindSoundDeviceSn = (params) => post("/api/merchant/bindSoundDeviceSn", params)

// 获取提现记录列表
export const getWithdrawLogList = (params) => post("/api/thirdWithdrawLog/list", params)

// 获取门店列表
export const getStoreList = (params) => post("/api/merchantStore/list", params)

// 获取商户所有门店列表
export const getMerchantStoreList = (params) => get("/api/merchantStore/allList")

// 获取门店统计列表
export const getStoreStatisticsList = (params) => post("/api/merchantStore/merchantStoreStatisticsList", params)

// 获取门店信息
export const getStore = (id) => get(`/api/merchantStore/info/${id}`)

// 新增门店
export const postAddStore = (data) => post('/api/merchantStore/save', data)

// 修改门店
export const postModifyStore = (data) => post('/api/merchantStore/update', data)

// 提现配置
export const getWithdrawConfig = () => get("/api/merchant/power/bank/withdraw/withdrawConfig")